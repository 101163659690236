import * as Sentry from "@sentry/remix";
import type { SQSRecord } from "aws-lambda";
import { Result } from "result-type-ts";
import { ZodError } from "zod";
import type { ConcreteConstructor } from "~/lib/utilTypes";

/**
 * A base-class for errors that sets the `name` property
 * equal to the name of the class that inherits from it.
 *
 * That helps visibility + grouping in Sentry
 */
export class ConnectApiError<Input = unknown> extends Error {
  input?: Input;

  constructor(cause?: Error | unknown, message?: string, input?: Input) {
    if (cause instanceof ZodError) {
      message = cause.issues
        .map((issue) => ({
          path: issue.path.join("."),
          message: issue.message,
          code: issue.code,
        }))
        .reduce(
          (msg, { code, message: zodMessage, path }) =>
            `${msg}; ${code} - ${zodMessage} @ ${path}`,
          message ?? "",
        );
    }

    super(message, { cause });
    this.name = this.constructor.name;
    this.message = message ?? this.name;
    this.input = input;
  }
}

export class SegmentTrackFailed extends ConnectApiError {
  constructor(cause: Error) {
    super(cause, "Segment track failed");
  }
}

/**
 * A base class to help with elevating uncaught Sentry errors into
 * a specific error class so that Sentry groups its
 */
abstract class UncaughtSentryErrorEvent extends ConnectApiError {
  // Return a named error if the text matches, otherwise null
  static tryFrom<E extends typeof UncaughtSentryErrorEvent>(
    event: Sentry.ErrorEvent,
  ): InstanceType<E> | null {
    if (event.message?.match(this.matchText)) {
      return this.new<E>(event);
    }

    return null;
  }

  protected static matchText: string;

  protected static new<E extends typeof UncaughtSentryErrorEvent>(
    event: Sentry.ErrorEvent,
  ) {
    const Cls = this as ConcreteConstructor<E>;
    return new Cls(event) as InstanceType<E>;
  }

  constructor(event: Sentry.ErrorEvent) {
    super(undefined, event.message);
    this.name = this.constructor.name;
  }
}
/**
 * Match on an external script by its domain name
 */
abstract class ExternalScriptUncaughtError extends UncaughtSentryErrorEvent {
  /**
   * Use the Sentry `ErrorEvent` to look into the parsed stack trace and check
   * where the top frame is coming from. If that's a URL where the `hostname`
   * matches with `matchText`, capture the error.
   */
  static tryFrom<E extends typeof ExternalScriptUncaughtError>(
    event: Sentry.ErrorEvent,
  ): InstanceType<E> | null {
    const exceptions = event.exception?.values ?? [];

    if (!exceptions.length) {
      return null;
    }

    const firstException: Sentry.Exception = exceptions[0];

    const stackFrames = firstException.stacktrace?.frames ?? [];

    const topFrameWithAbsPath = stackFrames.find((frame) => !!frame.abs_path);

    if (!topFrameWithAbsPath) {
      return null;
    }

    if (!topFrameWithAbsPath.abs_path) {
      return null;
    }

    const topFrameAbsolutePath: string = topFrameWithAbsPath.abs_path;

    const scriptUrlResult = Result.tryCatch(
      () => new URL(topFrameAbsolutePath),
    );

    if (scriptUrlResult.isFailure) {
      return null;
    }

    const scriptUrl: URL = scriptUrlResult.value;

    if (scriptUrl.hostname.match(this.matchText)) {
      return this.new<E>(event);
    }

    return null;
  }
}

export class UncaughtNetworkFetchError extends UncaughtSentryErrorEvent {
  static matchText: string = "NetworkError when attempting to fetch resource";
}

export class UncaughtLoadFailedError extends UncaughtSentryErrorEvent {
  static matchText: string = "Load failed";
}

export class CookieConsentScriptError extends ExternalScriptUncaughtError {
  static matchText: string = "cdn.cookielaw.org";
}

export class MutinyScriptError extends ExternalScriptUncaughtError {
  static matchText: string = "mutinycdn.com";
}

export class FailedToParseEventConsumerEventError extends ConnectApiError {
  readonly record: SQSRecord;

  constructor(record: SQSRecord) {
    super("Failed to parse event from record");
    this.name = this.constructor.name;
    this.record = record;
  }
}

export class UnableToAuthenticateError extends ConnectApiError {
  constructor(cause?: Error | unknown, message?: string) {
    super(cause, message);
    this.name = this.constructor.name;
  }
}

export function convertErrorsToPlainObj<T extends object | Error>(
  obj: T,
  maxDepth: number = 5,
  currentDepth = 0,
): { [K in keyof T]: T[K] } {
  // Object.keys(err) won't return an error's `message`, `stack`, and `cause`
  const keys = Object.getOwnPropertyNames(obj);

  return keys.reduce<typeof obj>((acc, key) => {
    const value = acc[key as keyof typeof acc];

    const isNotNil = value !== undefined && value !== null;

    if (isNotNil && value instanceof Error) {
      return {
        ...acc,
        [key]:
          currentDepth === maxDepth
            ? value
            : convertErrorsToPlainObj(value, maxDepth, currentDepth + 1),
      };
    }

    return {
      ...acc,
      [key]: value,
    };
  }, obj);
}
